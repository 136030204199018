import React from 'react';

import cx from 'classnames';

import { FeedbackModalConfig } from 'types/common';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as CheckIcon } from 'static/icons/check-circle.svg';

import styles from './FeedbackModal.module.scss';

interface FeedbackModalProps {
  config?: FeedbackModalConfig | null;
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ config, onClose }) => {
  return (
    <Modal className={styles.FeedbackModal} isOpen={!!config} onClose={onClose}>
      {config?.type === 'loading' && <Spinner className={styles.icon} />}
      {config?.type === 'error' && <CloseIcon className={cx(styles.icon, styles.error)} />}
      {config?.type === 'success' && <CheckIcon className={cx(styles.icon, styles.success)} />}
      <span className={styles.message}>{config?.message}</span>
    </Modal>
  );
};

export default FeedbackModal;
