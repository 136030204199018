import React, { useMemo, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';

import cx from 'classnames';

import { SVGIconType } from 'types/common';

import styles from './TextField.module.scss';

interface TextFieldProps {
  type?: 'text' | 'number' | 'password';
  name?: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler;
  onFocus?: React.FocusEventHandler;
  disabled?: boolean;
  multiline?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  label?: string;
  placeholder?: string;
  error?: string;
  className?: string;
  icon?: SVGIconType;
}

const TextField = forwardRef<(HTMLInputElement & HTMLTextAreaElement) | null, TextFieldProps>(
  (
    {
      type = 'text',
      name,
      value,
      onChange,
      onBlur,
      onFocus,
      disabled,
      multiline,
      min,
      max,
      maxLength,
      placeholder,
      label,
      error,
      icon: Icon,
      className,
    },
    ref
  ) => {
    const inputRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);
    useImperativeHandle<
      (HTMLInputElement & HTMLTextAreaElement) | null,
      (HTMLInputElement & HTMLTextAreaElement) | null
    >(ref, () => inputRef.current);

    const classNames = useMemo(
      () =>
        cx(
          styles.TextField,
          { [styles.hasError]: !!error, [styles.disabled]: disabled, [styles.hasIcon]: !!Icon },
          className
        ),
      [className, disabled, error, Icon]
    );

    const InputElement = useMemo(() => (multiline ? 'textarea' : 'input'), [multiline]);

    const handleTextareaResize = (element: HTMLTextAreaElement) => {
      // eslint-disable-next-line no-param-reassign
      element.style.height = 'inherit';
      const computed = window.getComputedStyle(element);

      const height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        element.scrollHeight +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      // eslint-disable-next-line no-param-reassign
      element.style.height = `${height}px`;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
      onChange && onChange(event);
      multiline && handleTextareaResize(event.target);
    };

    useEffect(() => {
      multiline && value && handleTextareaResize(inputRef.current as HTMLTextAreaElement);
    }, [multiline, value]);

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={classNames}>
        {(label || error) && <span className={styles.label}>{error || label}</span>}
        {Icon && <Icon className={styles.icon} />}
        <InputElement
          className={styles.input}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          type={type}
          min={min}
          max={max}
          maxLength={maxLength}
          placeholder={placeholder}
          ref={inputRef}
        />
      </label>
    );
  }
);

export default TextField;
