import React from 'react';
import ReactDOM from 'react-dom/client';

import { MetaMask } from '@web3-react/metamask';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';

import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { metaMask, hooks as metaMaskHooks } from 'config/connectors/metaMask';

import 'static/styles/index.scss';

const connectors: [MetaMask, Web3ReactHooks][] = [[metaMask, metaMaskHooks]];

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <App />
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
