import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as yup from 'yup';
import { toBn } from 'evm-bn';
import { useWeb3React } from '@web3-react/core';
import { metaMask } from 'config/connectors/metaMask';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NoMetaMaskError } from '@web3-react/metamask';
import { Web3Provider } from '@ethersproject/providers';

import TOKEN_ABI from 'config/abi/token';
import BRIDGE_ABI from 'config/abi/bridge';
import chainsPair from 'config/chainsPair';
import { BigNumber, Contract } from 'ethers';
import { Chain, ChainsPair } from 'types/web3';
import { FeedbackModalConfig } from 'types/common';
import { BSC_BRIDGE_ADDRESS, BSC_TOKEN_ADDRESS, ETH_BRIDGE_ADDRESS, ETH_TOKEN_ADDRES } from 'const/env';

import Button from 'components/Button';
import TextField from 'components/TextField';
import FeedbackModal from 'modals/FeedbackModal';

import { ReactComponent as ZenexLogo } from 'static/icons/zenex.svg';
import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as ArrowIcon } from 'static/icons/arrow-right.svg';
import { ReactComponent as ArrowsIcon } from 'static/icons/arrows-sideways.svg';

import styles from './App.module.scss';

interface TransferValues {
  amount: string | number;
}

const App: React.FC = () => {
  const { account, isActive, chainId, provider } = useWeb3React();
  const [feedbackModalConfig, setFeedbackModalConfig] = useState<FeedbackModalConfig | null>(null);

  const [pair, setPair] = useState<ChainsPair>(chainsPair);

  const [isConnectLoading, setIsConnectLoading] = useState(false);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [isSwitchNetworkLoading, setIsSwitchNetworkLoading] = useState(false);
  const [userBalance, setUserBalance] = useState<BigNumber>(BigNumber.from(0));

  const accountShort = useMemo(() => (account ? `${account.slice(0, 5)}...${account.slice(-3)}` : ''), [account]);

  const contracts = useMemo(
    () => ({
      [chainsPair.source.chainId]: {
        bridgeContract: new Contract(ETH_BRIDGE_ADDRESS, BRIDGE_ABI, provider?.getSigner()),
        tokenContract: new Contract(ETH_TOKEN_ADDRES, TOKEN_ABI, provider?.getSigner()),
      },
      [chainsPair.target.chainId]: {
        bridgeContract: new Contract(BSC_BRIDGE_ADDRESS, BRIDGE_ABI, provider?.getSigner()),
        tokenContract: new Contract(BSC_TOKEN_ADDRESS, TOKEN_ABI, provider?.getSigner()),
      },
    }),
    [provider]
  );

  const transferSchema: yup.SchemaOf<TransferValues> = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()
          .nullable()
          .min(0, 'Insufficient funds')
          .test('max', 'Insufficient funds', (value) =>
            userBalance.gte(toBn(value?.toString() || '0', pair.source.coinDecimals))
          )
          .required('Amount is required')
          .positive('Amount must be positive')
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
      }),
    [userBalance, pair]
  );

  const transferForm = useForm({
    mode: 'onTouched',
    resolver: yupResolver(transferSchema),
    defaultValues: {
      amount: '',
    },
  });

  const handleTransferSubmit = transferForm.handleSubmit(async (values: TransferValues) => {
    try {
      setIsTransferLoading(true);
      const sourceContracts = contracts[pair.source.chainId];
      const amount = toBn((values.amount as number).toString(), pair.source.coinDecimals);

      const approveTx = await sourceContracts.tokenContract.functions.approve(
        sourceContracts.bridgeContract.address,
        amount
      );
      await provider?.waitForTransaction(approveTx.hash);

      const transferTx = await sourceContracts.bridgeContract.functions.transferTokensToNewChain(amount);
      await provider?.waitForTransaction(transferTx.hash);
      setFeedbackModalConfig({
        type: 'success',
        message: 'Transfer completed successfully',
      });
      transferForm.reset({
        amount: '',
      });
    } catch (error) {
      if ((error as { reason: string })?.reason) {
        setFeedbackModalConfig({
          type: 'error',
          message: (error as { reason: string }).reason,
        });
      }
    } finally {
      setIsTransferLoading(false);
    }
  });

  const handleConnect = async (chainParams: Chain) => {
    try {
      setIsConnectLoading(true);
      metaMask.activate(chainParams);
    } catch (error) {
      if (error instanceof NoMetaMaskError) {
        window.open(`https://metamask.app.link/dapp/${window.location.host}/`, '_blank');
      } else if ((error as { code: number }).code === 4001) {
        setFeedbackModalConfig({ message: 'User rejected the request', type: 'error' });
      }
    } finally {
      setIsConnectLoading(false);
    }
  };

  const handleDisconnect = () => {
    if (metaMask.deactivate) {
      metaMask.deactivate();
    } else {
      metaMask.resetState();
    }
  };

  const handleSwitchPair = () => {
    setPair((prevPair) => ({ source: prevPair.target, target: prevPair.source }));
  };

  const getUserBalance = useCallback(async () => {
    try {
      const [balance]: [BigNumber] = await contracts[pair.source.chainId].tokenContract.functions.balanceOf(account);
      setUserBalance(balance);
    } catch (error) {
      console.log(error);
    }
  }, [pair, contracts, account]);

  const createProviderNetwork = useCallback(async (chainParams: Chain, networkProvider: Web3Provider) => {
    try {
      await networkProvider.send('wallet_addEthereumChain', [
        { ...chainParams, chainId: `0x${chainParams.chainId.toString(16)}` },
      ]);
    } catch {
      handleDisconnect();
      setFeedbackModalConfig({
        type: 'error',
        message: 'Error switching network. Please, try to switch or add it manually',
      });
    }
  }, []);

  const switchProviderNetwork = useCallback(
    async (chainParams: Chain, networkProvider: Web3Provider) => {
      try {
        setIsSwitchNetworkLoading(true);
        await networkProvider.send('wallet_switchEthereumChain', [
          { chainId: `0x${chainParams.chainId.toString(16)}` },
        ]);
      } catch (error) {
        if ((error as { code: number }).code === 4902) {
          await createProviderNetwork(chainParams, networkProvider);
        }
      } finally {
        setIsSwitchNetworkLoading(false);
      }
    },
    [createProviderNetwork]
  );

  useEffect(() => {
    metaMask.connectEagerly();
  }, []);

  useEffect(() => {
    if (isActive && provider && pair.source.chainId !== chainId) {
      switchProviderNetwork(pair.source, provider);
    } else if (!isActive && pair.source.chainId === chainId) {
      handleConnect(pair.source);
    }
  }, [isActive, pair, chainId, provider, switchProviderNetwork]);

  useEffect(() => {
    if (isActive) {
      const interval = setInterval(getUserBalance, 3000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isActive, getUserBalance, pair]);

  return (
    <>
      <div className={styles.App}>
        <header className={styles.header}>
          <figure className={styles.logo}>
            <ZenexLogo className={styles.icon} />
            <span className={styles.label}>ZENEX</span>
          </figure>
        </header>

        <main className={styles.main}>
          <section className={styles.card}>
            <h1 className={styles.title}>
              <span>ZENEX</span> BRIDGE
            </h1>

            {!isActive && (
              <Button
                className={styles.connectButton}
                onClick={() => handleConnect(pair.source)}
                isLoading={isConnectLoading}
              >
                Connect
              </Button>
            )}

            {isActive && (
              <Button
                className={styles.disconnectButton}
                variant="outlined"
                iconEnd={CloseIcon}
                onClick={handleDisconnect}
              >
                Connected: {accountShort}
              </Button>
            )}

            <div className={styles.switcher}>
              <TextField
                className={styles.input}
                label="Source"
                value={pair.source.chainName}
                icon={pair.source.icon}
              />
              <Button
                className={styles.switch}
                variant="outlined"
                color="white"
                isLoading={isSwitchNetworkLoading}
                onClick={handleSwitchPair}
              >
                <ArrowIcon className={styles.arrow} />
                <ArrowsIcon className={styles.arrows} />
              </Button>
              <TextField
                className={styles.input}
                label="Target"
                value={pair.target.chainName}
                icon={pair.target.icon}
              />
            </div>

            <form className={styles.form} onSubmit={handleTransferSubmit}>
              <Controller
                control={transferForm.control}
                name="amount"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={fieldState.error?.message}
                    label="Amount"
                    type="number"
                    placeholder="0.0"
                    disabled={!isActive}
                  />
                )}
              />

              <Button type="submit" color="white" disabled={!isActive} isLoading={isTransferLoading}>
                Transefer
              </Button>
            </form>
          </section>
        </main>

        <footer className={styles.footer}>
          <p className={styles.text}>© 2022 GOLDFORT OU. Issuer's representative financial service bitexpro.eu.</p>
        </footer>
      </div>

      <FeedbackModal config={feedbackModalConfig} onClose={() => setFeedbackModalConfig(null)} />
    </>
  );
};

export default App;
